<template>
  <div class="text" v-show="show">
    <div class="title">
      授权上传影像数据协议
    </div>
    <div class="desc">
      感谢您使用医学图像存储与传输管理软件（以下简称：“本软件”)。为了使您更有效、更安心的使用本软件，特向您做以下告知：
    </div>
    <div class="desc">
      1、本软件的服务主要是为了实现影像数据的存储、在线查阅。为此，需要您授权上传您的影像数据以便您的查阅和转发。若您是18周岁以下未成年人，在使用本服务前，应事先取得您家长或法定监护人的书面同意。
    </div>
    <div class="desc">
      2、本软件提供在线影像存储功能，以在线数字影像取代光盘存储，以供您在复诊及其他场景中快速便捷的查看使用，提高诊疗效率。平台对影像提供一段时间的免费存储和下载服务，在此之后，如您希望继续使用，本软件有权向您收取费用。
    </div>
    <div class="desc">
      3、您使用本服务视为您对本协议全部条款无保留的同意和遵守，授权本软件存储您的信息资料，授权本软件在保护个人信息资料的前提下，对信息数据进行统计分析。对本政策有任何疑问，您可以通过010-62669912进行咨询和建议。
    </div>
    <div class="desc">
      4、尊重和保护用户个人隐私信息是我方的原则底线，我方将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，保证不对外公开或向第三方透露您的个人隐私信息，或用户在使用服务时存储的非公开内容。同时，为了运营和改善我们的技术与服务，我方将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于我们向用户提供更好的用户体验和服务质量。我们会按照国家相关法律法规的要求，对您提供的个人信息严格保密，采取适宜的数据分类、备份、加密等措施保障信息安全，以保障您的个人信息安全。
    </div>
    <div class="desc">
      5、我们可能需要专业的技术服务方为本软件的运营提供技术支持，我们会与技术服务方共享您的个人信息，但我们会与其签署严格的保密协定，要求他们按照我们的说明以及其他任何相关的保密和安全措施来处理个人信息。
    </div>
    <div class="desc">
      6、您可以自行访问、更正和删除您的个人信息，改变您授权同意的范围和注销账户，或者通过系统中的【我的】-【意见反馈】与我们联系，我们会针对您的请求及时作出响应。在注销账户之后，我们将停止为您提供服务，并依据您的要求，删除您的个人敏感信息，法律法规另有规定的除外。
    </div>
    <div class="desc">
      7、在使用本软件服务期间，必须保护好您自己的帐号和密码，不得以任何方式将账号等个人资料转让、告知或借给他人。因您本人泄露而造成的任何损失由本人负责。
    </div>
    <div class="desc">
      8、您在线上与医生沟通过程中需提供真实完整、详细的信息。医生以此信息为判定基础，并尽可能利用所掌握的医学知识及临床经验给予相关的咨询建议。请您切勿故意隐瞒或虚报，因瞒报造成的不良后果，由您本人负责。该等建议并不构成诊疗意见，更不构成处方或电子处方，在任何情况下，处方药药品使用需前往医院并取得医生的处方单后才能购买使用。同样，您在系统中获得的医生答复、科普文章、医疗保健信息也不代表我方认同其观点或证实其真实性。如确有必要，您应当尽快到医院与医生进行面对面的治疗；我方不承诺网页上设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由我方实际控制的任何网页上的内容不承担责任。
    </div>
    <div class="desc">
        <p>
          9、您在使用本软件服务时不得利用本服务从事以下行为，包括但不限于:
        </p>
        <p>(1)发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容;</p>
        <p>(2)发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容;</p>
        <p>(3)虚构事实、隐瞒真相以误导、欺骗他人:</p>
        <p>(4)发表、传送、传播广告信息及垃圾信息;</p>
        <p>(5)从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</p>
    </div>
    <div class="desc">
      10、本软件服务是按照现有技术和条件所能达到的现状提供的。我们会尽最大努力为您提供服务，确保服务的连贯性和安全性;但不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险；对于因不可抗力或我方不能控制的原因造成的网络服务中断或其它缺陷，不承担任何责任，但将尽力减少因此而给您造成的损失和影响。
    </div>
    <div class="desc">      
      11、 本软件平台上的所有信息仅供参考，不做个别诊断、用药和治疗的依据。您在本平台获得的咨询意见不可替代线下医疗机构的医生诊断。如需诊断，请您去线下医疗机构进行诊疗。
    </div>
    <div class="desc">
      12、您可以自行访问、更正和删除您的个人信息，改变您授权同意的范围和注销账户，或者通过系统中的【我的】-【意见反馈】与我们联系，我们会针对您的请求及时作出响应。在注销账户之后，我们将停止为您提供服务，并依据您的要求，删除您的个人敏感信息，法律法规另有规定的除外。
    </div>
    <!-- <div class="xz">
      <img src="/static/xz/nzx.jpg" alt="">
    </div> -->
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show: false
      };
    },
    created() {
        if(sessionStorage.getItem('load') != 1){
            sessionStorage.setItem("load",1)
            window.location.reload();
            return false;
        }
        this.show = true
    },
    destroyed () {
        sessionStorage.removeItem('load')
    },
    mounted() {
    
    },
    computed: {},
    methods: {
    //   getQuery() {
    //     this.$axios2.post('/hosp/get').then(res => {
    //       this.name = res.data.name
    //     })
    //   }
    },
    watch: {
    }
  };
</script>
<style lang="less" scoped>
.txt{
    height: 100%;
    overflow: auto;
}
  .title {
    padding: 0.2rem;
    margin: 0.4rem 0.5rem;
    font-size: 0.72rem;
    color: #22455B;
    background-color: #BDE0F6;
    text-align: center;
    border-radius: 0.2rem;
    border: #F3FCFF 0.4rem solid;
  }
  .desc {
    padding: 0.2rem;
    margin: 0 0.5rem 0.2rem 0.5rem;
    font-size: 0.64rem;
    color: #22455B;
    background-color: #BDE0F6;
    border-radius: 0.2rem;
    text-indent: 2em;
    border: #F3FCFF 0.4rem solid;
  }
  .xz{
    width:100%;
    img{
      width:100%;
    }
  }
</style>

